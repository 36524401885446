import React from 'react';
import { Row, Col } from 'antd';
import './index.less';
import arrow from '../../img/solution/arrow.png';
import arrow2 from '../../img/solution/arrow2.png';
import topbj from '../../img/about/topbj.png';
import culture1 from '../../img/forwarder/1.jpg';
import culture4 from '../../img/forwarder/4.png';
import img1 from '../../img/forwarder/f/1.png';
import img2 from '../../img/forwarder/f/2.png';
import img3 from '../../img/forwarder/f/3.png';
import img4 from '../../img/forwarder/f/4.png';
import img5 from '../../img/forwarder/f/5.png';
import img6 from '../../img/forwarder/f/6.png';
import img7 from '../../img/forwarder/f/7.jpg';

export default class Forwarder extends React.Component {
  state = {
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
  };
  constructor(props) {
    super(props);
  }
  handleClick(info) {
    if (info === 'bottomjava') {
      document.getElementById('bottomjava').className = 'hiden';
      document.getElementById('leftjava').className = 'show';
      document.getElementById('java-describe').className = 'job-describe hiden';
    } else if (info === 'leftjava') {
      document.getElementById('bottomjava').className = 'show';
      document.getElementById('leftjava').className = 'hiden';
      document.getElementById('java-describe').className = 'job-describe show';
    } else if (info === 'leftfront') {
      document.getElementById('bottomfront').className = 'show';
      document.getElementById('leftfront').className = 'hiden';
      document.getElementById('front-describe').className = 'job-describe show';
    } else {
      document.getElementById('leftfront').className = 'show';
      document.getElementById('bottomfront').className = 'hiden';
      document.getElementById('front-describe').className =
        'job-describe hiden';
    }
  }

  render() {
    let dataList = [
      {
        pic: this.state.img1,
        name: '东莞市金玛物流有限公司',
        line: '东莞 → 上海',
        desc:
          '介绍：东莞市金玛物流有限公司承接东莞至全国整车零担货物运输，自备4.2米到17.5米大小车',
        address:
          '地址：一部地址：东莞市大朗镇康丰路巷头社区大朗物流中心三期C号',
        time: '2天',
        money: '面议',
        dengji: '2',
      },
      {
        pic: this.state.img2,
        name: '东莞市菜鸟仓储物流有限公司',
        line: '北京 → 广州',
        desc:
          '简介：东莞市菜鸟仓储物流有限公司（菜鸟智能物流）承接广东珠三角地区到全国各地整车零担货物运',
        address:
          '地址一：东莞市高步镇北王路百茂物流园',
        time: '3天',
        money: '1400元',
        dengji: '3',
      },
      {
        pic: this.state.img3,
        name: '广州创盛物流有限公司',
        line: '广州 → 上海',
        desc:
          '简介：广州创盛物流有限公司，自成立以来以优先的技术专业，严格的操作安全，自强不息的严格管理',
        address: '地址：广州市白云区龙陂口北街锦邦货运市场',
        time: '1天',
        money: '面议',
        dengji: '2',
      },
      {
        pic: this.state.img4,
        name: '江西威凯物流有限公司广州业务部',
        line: '广州 → 上海',
        desc:
          '简介：江西威凯物流有限公司广州业务部为您提供零担运输、整车运输等服务。运营及合作货运专线覆',
        address: '地址：广州市大源北路林安物流园物联网中心6楼613室',
        time: '2天',
        money: '面议',
        dengji: '4',
      },
      {
        pic: this.state.img5,
        name: '天津市百利东升物流有限公司',
        line: '天津 → 郑州',
        desc:
          '简介：【天津市百利东升物流有限公司】是一家长途运输公司物流网络覆盖全国大中城市承接天津至全',
        address: '地址：天津市北辰区佳丰道华北集团成邦物流园',
        time: '2天',
        money: '面议',
        dengji: '2',
      },
      {
        pic: this.state.img6,
        name: '天津宏泰金通物流有限公司',
        line: '天津 → 河南',
        desc: '简介：主营：天津至河南、广东、上海直达专线；   天津到河南专线：',
        address: '地址：天津市北辰区红旗路北兴物流园内',
        time: '3天',
        money: '面议',
        dengji: '3',
      },
      {
        pic: this.state.img7,
        name: '山西转型综改示范区阳曲园区京大货运部',
        line: '太原 → 北京',
        desc:
          '简介：太原市俊文货运中心货运中心承接全国各地整车、零担运输、轿车运输、长途搬家、包装等业务',
        address: '地址：山西综改示范区太原阳曲园区松庄路2号院南门1-3号库',
        time: '4天',
        money: '面议',
        dengji: '4',
      },
    ];
    return (
      <div style={{ width: '100%' }}>
        <Row className="join-box2"></Row>
        {dataList.map((v, i) => (
          <div
            style={{
              width: '1000px',
              margin: '0 auto',
              clear: 'both',
            }}
          >
            <div className="lineList">
              <div style={{ marginTop: '10px' }}>
                <div
                  style={{
                    width: '120px',
                    height: '100px',
                    float: 'left',
                    margin: '10px 0',
                  }}
                >
                  <img
                    src={v.pic || ''}
                    style={{ width: '120px', height: '100px', padding: '5px' }}
                  ></img>
                </div>
                <div
                  style={{ float: 'left', width: '450px', margin: '10px 0' }}
                >
                  <div className="companyName">
                    <a href={`#/fDetail?id=${i}`}>{v.name}</a>
                  </div>
                  <div className="xianlu">{v.line}</div>
                  <div className="introTip">{v.desc}</div>
                  <div className="leftAddress">{v.address}</div>
                </div>
                <div
                  style={{ float: 'left', width: '150px', paddingTop: '20px' }}
                >
                  <div className="shixiao">时效：{v.time}</div>
                  <div className="shixiao">运价：{v.money}</div>
                </div>
                <div
                  style={{ float: 'left', width: '150px', paddingTop: '25px' }}
                >
                  <div className="shixiao">
                    <span className="dengji rzlogo">先锋V{v.dengji}</span>
                  </div>
                </div>
                <div
                  style={{
                    width: '90px',
                    float: 'right',
                    paddingTop: '40px',
                  }}
                >
                  <a class="newFaHuoBtn" href={`#/fDetail?id=${i}`}>
                    查看
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
