import React from 'react';
import { enquireScreen } from 'enquire-js';
import { BackTop, Row, Col } from 'antd';
import topbj from '../../img/about/topbj.png';
import culture1 from '../../img/about/culture1.png';
import culture2 from '../../img/about/culture2.png';
import culture3 from '../../img/about/culture3.png';
import position from '../../img/about/map.png';
import positionSmall from '../../img/about/map-mobile.png';

import './index.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    let nowSize = document.body.clientWidth;
    return (
      <div className="about-main-container">
        <div className="about-top-box">
          <img src={topbj} alt="关于我们" className="about-top-bj" />
          <p className="about-top-title">
            致力于提升中国企业物流信息化水平。集合AI、大数据、物联网、传感等新技术，为企业提供先进的信息流、物流、资金流专业解决方案。
            优米拉基于Sass平台，支持PC端、APP、公众号、微信小程序多渠道登陆，无缝链接发货人、承运商、司机、收货人、一件查询，极速获取真实业务数据，全程可视化。信息实时共享，提前预警异常情况。
          </p>
        </div>
        <div className="about-box2">
          <h1 className="about-box2-title">企业文化</h1>
          <p className="about-box2-content">
            致力于提升数十万家物流公司信息化水平，
          </p>
          <p className="about-box2-content">
            未来重点为物流公司提供信息流、物流、商流的一站式解决方案
          </p>
          <Row className="about-box2-main">
            <Col xs={24} md={8} className="about-box2-item">
              <img src={culture1} />
              <p className="about-box2-item-title">使命</p>
              <span className="about-box2-item-describe">
                让中国物流人能获得
                <br />
                与世界一流物流企业一样的信息化服务
              </span>
            </Col>
            <Col xs={24} md={8} className="about-box2-item borderLeft">
              <img src={culture2} />
              <p className="about-box2-item-title">愿景</p>
              <span className="about-box2-item-describe">
                成为受人尊敬的
                <br />
                物流服务专家
              </span>
            </Col>
            <Col xs={24} md={8} className="about-box2-item borderLeft">
              <img src={culture3} />
              <p className="about-box2-item-title">服务理念</p>
              <span className="about-box2-item-describe">
                客户成功
                <br />
                才是我们的成功
              </span>
            </Col>
          </Row>
        </div>
        {/* <div className="about-box3">
          <img src={nowSize < 767 ? positionSmall : position} />
          <span className="about-box3-title">北京优米拉信息技术有限公司</span>
          <p className="about-box3-content">
            电话：400-698-5656
            <br />
            网址：https://www.youmila.com
            <br />
            地址：北京总部北京昌平区回龙观东大街首开广场408-5
          </p>
        </div> */}
        <BackTop />
      </div>
    );
  }
}
