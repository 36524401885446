import React from 'react';
import './park.less';
import { Row, Col } from 'antd';
import topbg from '../../img/solution/park-solution-box1.png';
import parkImg3 from '../../img/solution/park-box3.png';
import parkbox4Img1 from '../../img/solution/park-box-1.png';
import parkbox4Img2 from '../../img/solution/park-box-2.png';
import parkbox4Img3 from '../../img/solution/park-box-3.png';
import parkbox4Img4 from '../../img/solution/park-box-4.png';
import parkbox5 from '../../img/solution/park-box5.png';

export default class Park extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div className="park-title_bj">
          <div className="park-top-box">
            <img
              className="solve-bj"
              src={topbg}
              alt="物流园区解决方案"
              style={{ marginLeft: 0 }}
            />
            <p className="park-top-title">智慧物流园区解决方案</p>
            <span className="top-text">
              物流业务:整车、零担、配送、设备租赁，非物流业务:金融、汽修、汽配、卡车销售。
            </span>
          </div>
        </div>
        <div className="park-box2">
          <Row className="park-box2-main">
            <Col xs={24} md={12} className="park-box2-item">
              <span className="park-box2-item-icon">减</span>
              <p className="park-box2-item-title">
                减少中间环节
                <span></span>
              </p>
              <p className="park-box2-item-describe">
                应用信息整合与共享使物流干系人，应用平台手段，高效的信息传输，标准的流程管理，应用IT工具的智能化应用减少物流应用环节。
              </p>
            </Col>
            <Col xs={24} md={12} className="park-box2-item">
              <span className="park-box2-item-icon">降</span>
              <p className="park-box2-item-title">
                降低物流成本
                <span></span>
              </p>
              <p className="park-box2-item-describe">
                通过平台的高效应用，减少人工操作，通过大数据应用的智能分析，减少运作过程的预警、报警的控制。通过信息对称手段减少沟通成本和其他边际成本。
              </p>
            </Col>
            <Col xs={24} md={12} className="park-box2-item mrgTop60">
              <span className="park-box2-item-icon">管</span>
              <p className="park-box2-item-title">
                综合物流管理
                <span></span>
              </p>
              <p className="park-box2-item-describe">
                通过平台产品的应用，对商流、物流、信息流进行全面、综合的管理，协同保障相互间的衔接控制。通过平台手段使得物流业务标准化，实现企业SOP,同时通过平台技术提升用户体验。
              </p>
            </Col>
            <Col xs={24} md={12} className="park-box2-item mrgTop60">
              <span className="park-box2-item-icon">安</span>
              <p className="park-box2-item-title">
                安全风险控制
                <span></span>
              </p>
              <p className="park-box2-item-describe">
                通过平台手段，为园区内客户、驻户、合作伙伴等，提供安全保障的体验；通过平台可视化管理机制，监督和管控物流全过程，保障交易安全、运作安全、异常事务风险控制
              </p>
            </Col>
          </Row>
        </div>
        <img src={parkImg3} className="park-box3" alt="智慧物流园" />
        <div className="park-box4">
          <Row className="park-box4-main">
            <Col xs={24} md={6} className="park-box4-item">
              <img src={parkbox4Img1} className="park-box4-item-img" />
              <span className="park-box4-item-title">智慧</span>
            </Col>
            <Col xs={24} md={6} className="park-box4-item">
              <img src={parkbox4Img2} className="park-box4-item-img" />
              <span className="park-box4-item-title">高效</span>
            </Col>
            <Col xs={24} md={6} className="park-box4-item">
              <img src={parkbox4Img3} className="park-box4-item-img" />
              <span className="park-box4-item-title">协同</span>
            </Col>
            <Col xs={24} md={6} className="park-box4-item">
              <img src={parkbox4Img4} className="park-box4-item-img" />
              <span className="park-box4-item-title">共享</span>
            </Col>
          </Row>
        </div>
        <div className="park-box5 bgF9">
          <p className="park-box5-title">智慧物流园建设愿景</p>
          <img src={parkbox5} alt="智慧物流园建设愿景" />
        </div>
        <div className="onTrial-button-box">
          <span className="onTrial-button">预约试用</span>
          <span className="onTrial-tell">400-698-5656</span>
        </div>
      </div>
    );
  }
}
