import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import './index.less';
import arrow from '../../img/solution/arrow.png';
import arrow2 from '../../img/solution/arrow2.png';
import httpDataList from '../Forwarder/httpData';

export default class DespatchingCenter extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    httpDataList: [],
  };
  componentWillMount() {
    this.refresh();
  }
  handleClick(info) {
    if (info === 'bottomjava') {
      document.getElementById('bottomjava').className = 'hiden';
      document.getElementById('leftjava').className = 'show';
      document.getElementById('java-describe').className = 'job-describe hiden';
    } else if (info === 'leftjava') {
      document.getElementById('bottomjava').className = 'show';
      document.getElementById('leftjava').className = 'hiden';
      document.getElementById('java-describe').className = 'job-describe show';
    } else if (info === 'leftfront') {
      document.getElementById('bottomfront').className = 'show';
      document.getElementById('leftfront').className = 'hiden';
      document.getElementById('front-describe').className = 'job-describe show';
    } else {
      document.getElementById('leftfront').className = 'show';
      document.getElementById('bottomfront').className = 'hiden';
      document.getElementById('front-describe').className =
        'job-describe hiden';
    }
  }
  refresh() {
    let dataList = [
      {
        idd: 0,
        pic: this.state.img1,
        name: '东莞市金玛物流有限公司',
        line: '东莞 → 上海',
        desc:
          '介绍：东莞市金玛物流有限公司承接东莞至全国整车零担货物运输，自备4.2米到17.5米大小车',
        address:
          '地址：一部地址：东莞市大朗镇康丰路巷头社区大朗物流中心三期C号',
        time: '2天',
        money: '面议',
        dengji: '2',
      },
      {
        idd: 1,
        pic: this.state.img2,
        name: '东莞市菜鸟仓储物流有限公司',
        line: '北京 → 广州',
        desc:
          '简介：东莞市菜鸟仓储物流有限公司（菜鸟智能物流）承接广东珠三角地区到全国各地整车零担货物运',
        address: '地址一：东莞市高步镇北王路百茂物流园',
        time: '3天',
        money: '1400元',
        dengji: '3',
      },
      {
        idd: 2,
        pic: this.state.img3,
        name: '广州创盛物流有限公司',
        line: '广州 → 上海',
        desc:
          '简介：广州创盛物流有限公司，自成立以来以优先的技术专业，严格的操作安全，自强不息的严格管理',
        address: '地址：广州市白云区龙陂口北街锦邦货运市场',
        time: '1天',
        money: '面议',
        dengji: '2',
      },
      {
        idd: 3,
        pic: this.state.img4,
        name: '江西威凯物流有限公司广州业务部',
        line: '广州 → 上海',
        desc:
          '简介：江西威凯物流有限公司广州业务部为您提供零担运输、整车运输等服务。运营及合作货运专线覆',
        address: '地址：广州市大源北路林安物流园物联网中心6楼613室',
        time: '2天',
        money: '面议',
        dengji: '4',
      },
      {
        idd: 4,
        pic: this.state.img5,
        name: '天津市百利东升物流有限公司',
        line: '天津 → 郑州',
        desc:
          '简介：【天津市百利东升物流有限公司】是一家长途运输公司物流网络覆盖全国大中城市承接天津至全',
        address: '地址：天津市北辰区佳丰道华北集团成邦物流园',
        time: '2天',
        money: '面议',
        dengji: '2',
      },
      {
        idd: 5,
        pic: this.state.img6,
        name: '天津宏泰金通物流有限公司',
        line: '天津 → 河南',
        desc: '简介：主营：天津至河南、广东、上海直达专线；   天津到河南专线：',
        address: '地址：天津市北辰区红旗路北兴物流园内',
        time: '3天',
        money: '面议',
        dengji: '3',
      },
      {
        idd: 6,
        pic: this.state.img7,
        name: '山西转型综改示范区阳曲园区京大货运部',
        line: '太原 → 北京',
        desc:
          '简介：太原市俊文货运中心货运中心承接全国各地整车、零担运输、轿车运输、长途搬家、包装等业务',
        address: '地址：山西综改示范区太原阳曲园区松庄路2号院南门1-3号库',
        time: '4天',
        money: '面议',
        dengji: '4',
      },
    ];
    let id = Math.floor(Math.random() * 10 + 1);
    let arr = httpDataList[`${id}`];
    arr.forEach((item, index) => {
      let s = Math.floor(Math.random() * 6 + 1);
      console.log(s);
      // item.forwarder=dataList[s].name
      item = Object.assign(item, dataList[s]);
    });
    console.log(arr);

    this.setState({ httpDataList: arr });
  }
  gotoOrder(item) {
    window.localStorage.setItem('_dr_', JSON.stringify(item));
    window.location.href = '#/fDetailhw?id=' + item.idd;
  }
  getCurrentDate() {
    // 获取当前日期
    var date = new Date();

    // 获取当前月份
    var nowMonth = date.getMonth() + 1;

    // 获取当前是几号
    var strDate = date.getDate();

    // 添加分隔符“-”
    var seperator = '-';

    // 对月份进行处理，1-9月在前面添加一个“0”
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = '0' + nowMonth;
    }

    // 对月份进行处理，1-9号在前面添加一个“0”
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate;
    }

    // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
    var nowDate =
      date.getFullYear() + seperator + nowMonth + seperator + strDate;
    return nowDate;
  }

  render() {
    let fhList = [
      {
        name: '刘先生',
        goods: '饲料',
        city: '天津',
        weight: '6~10吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '张先生',
        goods: '压缩机',
        city: '天津',
        weight: '8~22吨',
        car: '平板',
        carLength: '9.6米',
        carDate: '2020-08-10',
      },
      {
        name: '王先生',
        goods: '百货',
        city: '鞍山',
        weight: '4吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '李先生',
        goods: '空压机及配件',
        city: '衢州市',
        weight: '6~8吨',
        car: '高栏',
        carLength: '6.2米',
        carDate: '2020-08-10',
      },
      {
        name: '李先生',
        goods: '封头铁板',
        city: '衢州市',
        weight: '25~25吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '严经理',
        goods: '饮料',
        city: '成都市',
        weight: '2.7吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '黄先生',
        goods: '钢材',
        city: '佛山市',
        weight: '31.5吨',
        car: '高栏车',
        carLength: '12.5-13米',
        carDate: '2020-08-10',
      },
      {
        name: '魏经理',
        goods: '面粉',
        city: '河南省-周口市',
        weight: '32吨',
        car: '整车配货',
        carLength: '9米6',
        carDate: '2020-08-10',
      },
      {
        name: '梁',
        goods: '建材',
        city: '河北省-保定市',
        weight: '1.5吨',
        car: '整车配货',
        carLength: '箱式',
        carDate: '2020-08-10',
      },
      {
        name: '龚师傅',
        goods: '日用百货',
        city: '南通市',
        weight: '7.4吨',
        car: '整车配货',
        carLength: '6.2米',
        carDate: '2020-08-10',
      },
      {
        name: '衡水市瑞飞运输有限公司',
        goods: '设备',
        city: '衡水',
        weight: '32吨',
        car: '整车配货',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
    ];

    return (
      <Fragment>
        <Row className="join-box"></Row>
        <div className="c6-warper">
          <div className="c6-header">
            <div>热门货源</div>
            <div
              onClick={() => {
                this.refresh();
              }}
            >
              <span className="shuaxin"></span>
              <span>换一批</span>
            </div>
          </div>
          <div className="b-warper">
            {this.state.httpDataList.map((item) => (
              <div
                className="block-h"
                onClick={() => {
                  this.gotoOrder(item);
                }}
              >
                <div className="ma_host_city_l_c_t">
                  <span>{item.p1.split('/')[0]}</span> <em></em>{' '}
                  <span>{item.p2.split('/')[0]}</span>
                  <i class="host_city_blue">直达</i>
                </div>
                <div className="ma_host_city_l_c_m">
                  <span>{`${item.p1.split('/')[1]}-${
                    item.p1.split('/')[2]
                  }`}</span>
                  <span class="host_city_last">{`${item.p2.split('/')[1]}-${
                    item.p2.split('/')[2]
                  }`}</span>
                  <div class="cleardiv"></div>
                  <p class="host_city_pri">
                    <span>
                      <i class="host_city_pri_z"></i>
                      <span>
                        重货<i>{item.p4}</i>
                      </span>
                    </span>
                    <span class="host_city_pri_l">
                      <i class="host_city_pri_p"></i>
                      <span>
                        泡货<i>{item.p6}</i>
                      </span>
                    </span>
                    <em class="clear"></em>
                  </p>
                </div>
                <div class="ma_host_city_l_c_b">
                  <p>
                    <em></em>
                    {item.name}
                  </p>
                  <p class="ma_host_be">
                    <span>
                      <i class="ma_host_be_pri">价格电议</i>
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  }
}
