const httpDataList = {
  '1': [
    {
      p1: '河北/石家庄市/灵寿县',
      p2: '甘肃/张掖市/山丹县',
      p3: 10000,
      p4: '30吨以上',
      p5: 1000,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '杨婷婷',
      p8: '玉米',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2020-02-28 12:04:05',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '4073',
      p16: '0',
      createdate1: {},
      p15: 'YTT666',
      p18: {},
      p17: {},
      p19: {},
      id: 9381,
      creator: 'YTT666',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '玉米',
      name2: {},
      name1: {},
    },
    {
      p1: '安徽/合肥市/瑶海区',
      p2: '山东/青岛市/黄岛区',
      p3: 100000,
      p4: '10吨以上',
      p5: 100000,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '杨婷婷',
      p8: '100000000000',
      bigfield: {},
      p9: 1.0e7,
      istms: {},
      p10: '2020-02-19 19:15:27',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '4073',
      p16: '0',
      createdate1: {},
      p15: 'YTT666',
      p18: {},
      p17: '',
      p19: {},
      id: 9382,
      creator: 'YTT666',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '100000000000',
      name2: {},
      name1: {},
    },
    {
      p1: '陕西/西安市/新城区',
      p2: '山西/太原市/杏花岭区',
      p3: 23,
      p4: '0-100kg',
      p5: 32,
      ckstatus: {},
      p6: '20-40立方米',
      p7: '杨婷婷',
      p8: '22',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2020-02-04 20:19:20',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '4073',
      p16: '0',
      createdate1: {},
      p15: 'YTT666',
      p18: {},
      p17: {},
      p19: {},
      id: 9380,
      creator: 'YTT666',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '22',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/焦作市/温县',
      p2: '上海/上海市/宝山区',
      p3: 25000,
      p4: '600kg',
      p5: 50,
      ckstatus: {},
      p6: '40-70立方米',
      p7: 'qc',
      p8: '钢结构H型钢',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-12-25 16:38:10',
      p12: '不允许',
      p11:
        '需要5-7辆车。钢构大概20-30吨，每辆车装满为止，但不会超过30吨。装2层，底板自带5根垫木。运费能给6800。12月26号一早过去装，联系17554167076',
      p14: 0,
      p13: '3986',
      p16: '0',
      createdate1: {},
      p15: 'qc123',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9378,
      creator: 'qc123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '钢结构H型钢',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/通辽市/科尔沁区',
      p2: '黑龙江/牡丹江市/东宁县',
      p3: 32000,
      p4: '5000kg以上',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '李雪松',
      p8: '整车',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-12-22 11:26:21',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '4028',
      p16: '0',
      createdate1: {},
      p15: 'lxs123456',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9377,
      creator: 'lxs123456',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '整车',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/通辽市/科尔沁区',
      p2: '黑龙江/牡丹江市/东宁县',
      p3: 32000,
      p4: '10吨以上',
      p5: 10,
      ckstatus: {},
      p6: '10-20立方米',
      p7: '李雪松',
      p8: '整车',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-12-22 11:24:26',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '4028',
      p16: '0',
      createdate1: {},
      p15: 'lxs123456',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9376,
      creator: 'lxs123456',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '整车',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/南京市/六合区',
      p2: '上海/上海市/金山区',
      p3: 1000,
      p4: '9吨',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '陆先生',
      p8: '鹅卵石',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-11-28 16:35:33',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3969',
      p16: '0',
      createdate1: {},
      p15: 'luluai1',
      p18: {},
      p17: '',
      p19: {},
      id: 9373,
      creator: 'luluai1',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '鹅卵石',
      name2: {},
      name1: {},
    },
    {
      p1: '江西/上饶市/余干县',
      p2: '浙江/宁波市/北仑区',
      p3: 33000,
      p4: '30吨以上',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '杨浩洋',
      p8: '木材',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-11-19 15:28:23',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3949',
      p16: '0',
      createdate1: {},
      p15: 'q1306900295',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9372,
      creator: 'q1306900295',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '木材',
      name2: {},
      name1: {},
    },
    {
      p1: '江西/鹰潭市/余江县',
      p2: '江西/上饶市/广丰区',
      p3: 33000,
      p4: '28吨',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '杨浩洋',
      p8: '重货',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-11-19 15:25:47',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3949',
      p16: '0',
      createdate1: {},
      p15: 'q1306900295',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9371,
      creator: 'q1306900295',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '重货',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/安阳市/林州市',
      p2: '天津/天津市/武清区',
      p3: 30000,
      p4: '5000kg以上',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '郭伟',
      p8: '汽车配件',
      bigfield: {},
      p9: 25,
      istms: {},
      p10: '2019-11-06 09:58:15',
      p12: '不允许',
      p11: '每托1吨左右',
      p14: 0,
      p13: '3923',
      p16: '0',
      createdate1: {},
      p15: 'gw6865365',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9368,
      creator: 'gw6865365',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '汽车配件',
      name2: {},
      name1: {},
    },
  ],

  '2': [
    {
      p1: '重庆/重庆市/南岸区',
      p2: '重庆/重庆市/巫山县',
      p3: 2500,
      p4: '23吨',
      p5: 43,
      ckstatus: {},
      p6: '40-70立方米',
      p7: '曾利',
      p8: '纸巾',
      bigfield: {},
      p9: 255,
      istms: {},
      p10: '2019-10-16 14:53:39',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3893',
      p16: '0',
      createdate1: {},
      p15: 'zengli',
      p18: '5-8米',
      p17: '7.6',
      p19: {},
      id: 9367,
      creator: 'zengli',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '纸巾',
      name2: {},
      name1: {},
    },
    {
      p1: '辽宁/沈阳市/新民市',
      p2: '广东/中山市/古镇镇',
      p3: 545,
      p4: '500-1000kg',
      p5: 3.7,
      ckstatus: {},
      p6: '2-5立方米',
      p7: '姜继伟',
      p8: '工艺品',
      bigfield: {},
      p9: 22,
      istms: {},
      p10: '2019-09-22 11:47:18',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3852',
      p16: '0',
      createdate1: {},
      p15: 'jjw8884861',
      p18: {},
      p17: '',
      p19: {},
      id: 9366,
      creator: 'jjw8884861',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '工艺品',
      name2: {},
      name1: {},
    },
    {
      p1: '福建/厦门市/海沧区',
      p2: '湖北/随州市/曾都区',
      p3: 7200,
      p4: '4000kg',
      p5: 32,
      ckstatus: {},
      p6: '20-40立方米',
      p7: '三固科技',
      p8: '设备',
      bigfield: {},
      p9: 12,
      istms: {},
      p10: '2019-09-09 17:01:27',
      p12: '不允许',
      p11:
        '设备一车共计12台，长宽高1.7*1.05*1.8米（尺寸计算含包装），重量600kg每台，已缠膜防撞处理。设备不可淋雨不可倒置不可叠放。总体车长至少需要10.2米。10月初出一车，10月底出一车，共计2车货。',
      p14: 0,
      p13: '3837',
      p16: '0',
      createdate1: {},
      p15: 'sangu',
      p18: '10-14米',
      p17: '10.5',
      p19: {},
      id: 9365,
      creator: 'sangu',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '设备',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/昌平区',
      p2: '广东/深圳市/福田区',
      p3: 10,
      p4: '0-100kg',
      p5: 2.86,
      ckstatus: {},
      p6: '2-5立方米',
      p7: 'xingjikang123',
      p8: '国画',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-08-29 08:48:20',
      p12: '允许',
      p11: '装裱好的国画，怕压、怕水',
      p14: 0,
      p13: '3811',
      p16: '0',
      createdate1: {},
      p15: 'xingjikang123',
      p18: '3-5米',
      p17: '3',
      p19: {},
      id: 9364,
      creator: 'xingjikang123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '国画',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/南京市/江宁区',
      p2: '江苏/徐州市/睢宁县',
      p3: 120,
      p4: '100-200kg',
      p5: 1.7,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '赵成龙',
      p8: '电动车',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-08-09 09:50:31',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3778',
      p16: '0',
      createdate1: {},
      p15: 'zclongsir',
      p18: {},
      p17: '',
      p19: {},
      id: 9363,
      creator: 'zclongsir',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '电动车',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/沧州市/南皮县',
      p2: '河南/新乡市/封丘县',
      p3: 4000,
      p4: '45吨',
      p5: 40,
      ckstatus: {},
      p6: '40-70立方米',
      p7: '李书锋',
      p8: '泡沫箱',
      bigfield: {},
      p9: 380,
      istms: {},
      p10: '2019-08-02 14:32:07',
      p12: '允许',
      p11: '6.8米、7.7米、9.6米都可以，货物不沉就是占地方。15631787333',
      p14: 0,
      p13: '3768',
      p16: '0',
      createdate1: {},
      p15: 'JGZY315',
      p18: '5-8米',
      p17: '6.8',
      p19: {},
      id: 9362,
      creator: 'JGZY315',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '泡沫箱',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/苏州市/虎丘区',
      p2: '广东/广州市/天河区',
      p3: 50,
      p4: '0-100kg',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: 'fq',
      p8: '服装',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2019-07-04 17:38:09',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3739',
      p16: '0',
      createdate1: {},
      p15: 'futurefu',
      p18: {},
      p17: '',
      p19: {},
      id: 9360,
      creator: 'futurefu',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '服装',
      name2: {},
      name1: {},
    },
    {
      p1: '吉林/四平市/铁东区',
      p2: '甘肃/酒泉市/敦煌市',
      p3: 25,
      p4: '0-100kg',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '盛世秸秆制品科技有限公司',
      p8: '餐具',
      bigfield: {},
      p9: 12,
      istms: {},
      p10: '2019-04-11 15:14:44',
      p12: '允许',
      p11: '12箱餐具',
      p14: 0,
      p13: '3627',
      p16: '0',
      createdate1: {},
      p15: 'liuyanfei',
      p18: {},
      p17: '',
      p19: {},
      id: 9358,
      creator: 'liuyanfei',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '餐具',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/石家庄市/晋州市',
      p2: '江苏/常州市/天宁区',
      p3: 5000,
      p4: '10吨',
      p5: 15,
      ckstatus: {},
      p6: '10-20立方米',
      p7: '顺速达',
      p8: '纤维素',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2019-04-08 09:44:09',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3620',
      p16: '0',
      createdate1: {},
      p15: 'q2226437082',
      p18: {},
      p17: '',
      p19: {},
      id: 9357,
      creator: 'q2226437082',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '纤维素',
      name2: {},
      name1: {},
    },
    {
      p1: '四川/遂宁市/船山区',
      p2: '湖北/孝感市/安陆市',
      p3: 4000,
      p4: '500kg',
      p5: 4,
      ckstatus: {},
      p6: '2-5立方米',
      p7: '杨先生',
      p8: '管材',
      bigfield: {},
      p9: 1000,
      istms: {},
      p10: '2019-03-19 14:53:55',
      p12: '允许',
      p11: '保温管',
      p14: 0,
      p13: '3591',
      p16: '0',
      createdate1: {},
      p15: 'scjieteng',
      p18: '3-5米',
      p17: '4.2',
      p19: {},
      id: 9354,
      creator: 'scjieteng',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '管材',
      name2: {},
      name1: {},
    },
  ],

  '3': [
    {
      p1: '四川/遂宁市/船山区',
      p2: '湖北/孝感市/安陆市',
      p3: 4000,
      p4: '7000kg以上',
      p5: 5,
      ckstatus: {},
      p6: '5-10立方米',
      p7: '杨先生',
      p8: '管材',
      bigfield: {},
      p9: 1000,
      istms: {},
      p10: '2019-03-19 14:53:00',
      p12: '允许',
      p11: '保温管',
      p14: 0,
      p13: '3591',
      p16: '0',
      createdate1: {},
      p15: 'scjieteng',
      p18: '3-5米',
      p17: '4.2',
      p19: {},
      id: 9353,
      creator: 'scjieteng',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '管材',
      name2: {},
      name1: {},
    },
    {
      p1: '辽宁/抚顺市/新抚区',
      p2: '河北/石家庄市/晋州市',
      p3: 10000,
      p4: '23吨',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '简丹物流',
      p8: '车',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-03-08 13:20:38',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2086',
      p16: '0',
      createdate1: {},
      p15: 'l11111111111',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9352,
      creator: 'l11111111111',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '车',
      name2: {},
      name1: {},
    },
    {
      p1: '广西/柳州市/城中区',
      p2: '上海/上海市/黄浦区',
      p3: 10000,
      p4: '40吨',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '自行车',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-03-07 14:29:38',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9350,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '自行车',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/郑州市/中原区',
      p2: '四川/泸州市/江阳区',
      p3: 30000,
      p4: '40吨',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '国际物流',
      p8: '家具',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-03-07 14:27:28',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9349,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '重庆/重庆市/九龙坡区',
      p2: '四川/达州市/达川区',
      p3: 2200,
      p4: '40吨',
      p5: 4,
      ckstatus: {},
      p6: '2-5立方米',
      p7: '亿禾建材',
      p8: '电机',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-03-07 09:30:12',
      p12: '允许',
      p11: '电机设备，重22吨，长3.6米，宽2.8米，高3.2米。可拼货',
      p14: 0,
      p13: '2940',
      p16: '0',
      createdate1: {},
      p15: 'angel',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9347,
      creator: 'angel',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '电机',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/徐州市/鼓楼区',
      p2: '陕西/西安市/新城区',
      p3: 10000,
      p4: '10吨',
      p5: 50,
      ckstatus: {},
      p6: '40-70立方米',
      p7: '凯江物流',
      p8: '粮食',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-03-06 14:04:40',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2082',
      p16: '0',
      createdate1: {},
      p15: 'llf123',
      p18: '8-10米',
      p17: '9.6',
      p19: {},
      id: 9346,
      creator: 'llf123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/房山区',
      p2: '山东/东营市/东营区',
      p3: 5000,
      p4: '10吨',
      p5: 30,
      ckstatus: {},
      p6: '20-40立方米',
      p7: '凯江物流',
      p8: '服装',
      bigfield: {},
      p9: 15,
      istms: {},
      p10: '2019-03-06 13:45:47',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2082',
      p16: '0',
      createdate1: {},
      p15: 'llf123',
      p18: {},
      p17: '',
      p19: {},
      id: 9345,
      creator: 'llf123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '服装',
      name2: {},
      name1: {},
    },
    {
      p1: '广东/深圳市/罗湖区',
      p2: '上海/上海市/徐汇区',
      p3: 20000,
      p4: '10吨',
      p5: 60,
      ckstatus: {},
      p6: '40-70立方米',
      p7: '速速快运',
      p8: '货',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-03-05 09:47:06',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '8-10米',
      p17: '9.6',
      p19: {},
      id: 9344,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '货',
      name2: {},
      name1: {},
    },
    {
      p1: '福建/莆田市/涵江区',
      p2: '江西/景德镇市/乐平市',
      p3: 0,
      p4: '0-100kg',
      p5: 6,
      ckstatus: {},
      p6: '5-10立方米',
      p7: '林玉峰',
      p8: '童鞋',
      bigfield: {},
      p9: 16,
      istms: {},
      p10: '2019-03-02 16:15:35',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3523',
      p16: '0',
      createdate1: {},
      p15: 'rogerlim',
      p18: {},
      p17: '',
      p19: {},
      id: 9342,
      creator: 'rogerlim',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '童鞋',
      name2: {},
      name1: {},
    },
    {
      p1: '辽宁/沈阳市/和平区',
      p2: '内蒙古/赤峰市/红山区',
      p3: 20000,
      p4: '43吨',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '货',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-02-28 10:19:11',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9341,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '货',
      name2: {},
      name1: {},
    },
  ],

  '4': [
    {
      p1: '内蒙古/赤峰市/松山区',
      p2: '河北/保定市/望都县',
      p3: 10000,
      p4: '43吨',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '简丹物流',
      p8: '材料',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-02-27 09:25:10',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2086',
      p16: '0',
      createdate1: {},
      p15: 'l11111111111',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9340,
      creator: 'l11111111111',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '材料',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/赤峰市/元宝山区',
      p2: '山东/潍坊市/潍城区',
      p3: 32000,
      p4: '6000kg',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '简丹物流',
      p8: '重货',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2019-02-27 09:21:03',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2086',
      p16: '0',
      createdate1: {},
      p15: 'l11111111111',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9339,
      creator: 'l11111111111',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '重货',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/承德市/双桥区',
      p2: '陕西/西安市/新城区',
      p3: 3000,
      p4: '6000kg',
      p5: 400,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '家具',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2019-02-26 09:51:53',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9338,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '吉林/松原市/宁江区',
      p2: '河北/张家口市/桥东区',
      p3: 1200,
      p4: '6000kg',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '普货',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-02-26 09:50:15',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9337,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '普货',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/廊坊市/广阳区',
      p2: '辽宁/阜新市/阜新蒙古族自治县',
      p3: 5700,
      p4: '15吨以上',
      p5: 6,
      ckstatus: {},
      p6: '5-10立方米',
      p7: '北京燕通石油化工有限公司',
      p8: '润滑油',
      bigfield: {},
      p9: 30,
      istms: {},
      p10: '2019-02-25 16:11:58',
      p12: '不允许',
      p11: '大铁桶',
      p14: 0,
      p13: '3508',
      p16: '0',
      createdate1: {},
      p15: 'bjytsh',
      p18: '3-5米',
      p17: '4.2',
      p19: {},
      id: 9336,
      creator: 'bjytsh',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '润滑油',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/周口市/川汇区',
      p2: '山东/济宁市/任城区',
      p3: 5000,
      p4: '15吨以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '粮食',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2019-02-25 09:30:26',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9335,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '安徽/蚌埠市/蚌山区',
      p2: '山东/临沂市/兰山区',
      p3: 30000,
      p4: '20吨',
      p5: 1000,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '化肥',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-02-25 09:26:07',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: {},
      p17: '',
      p19: {},
      id: 9334,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '化肥',
      name2: {},
      name1: {},
    },
    {
      p1: '浙江/温州市/永嘉县',
      p2: '广东/汕尾市/海丰县',
      p3: 3000,
      p4: '20吨',
      p5: 15,
      ckstatus: {},
      p6: '10-20立方米',
      p7: '冉义',
      p8: '教玩具',
      bigfield: {},
      p9: 60,
      istms: {},
      p10: '2019-02-20 11:28:23',
      p12: '允许',
      p11: '教玩具轻泡货',
      p14: 0,
      p13: '3472',
      p16: '0',
      createdate1: {},
      p15: 'ranyi',
      p18: {},
      p17: '',
      p19: {},
      id: 9332,
      creator: 'ranyi',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '教玩具',
      name2: {},
      name1: {},
    },
    {
      p1: '山西/太原市/小店区',
      p2: '湖南/长沙市/芙蓉区',
      p3: 1500,
      p4: '30吨以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '家具',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2019-02-19 09:20:09',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9331,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '广西/南宁市/兴宁区',
      p2: '上海/上海市/黄浦区',
      p3: 2000,
      p4: '4500kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '普货',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-02-19 09:06:44',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9330,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '普货',
      name2: {},
      name1: {},
    },
  ],

  '5': [
    {
      p1: '甘肃/兰州市/城关区',
      p2: '贵州/六盘水市/钟山区',
      p3: 5000,
      p4: '2400kg以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '凯江物流',
      p8: '煤',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-02-14 09:45:43',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2082',
      p16: '0',
      createdate1: {},
      p15: 'llf123',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9329,
      creator: 'llf123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '煤',
      name2: {},
      name1: {},
    },
    {
      p1: '吉林/辽源市/龙山区',
      p2: '辽宁/铁岭市/银州区',
      p3: 2000,
      p4: '2400kg以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '凯江物流',
      p8: '玉米',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-02-14 09:42:02',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2082',
      p16: '0',
      createdate1: {},
      p15: 'llf123',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9328,
      creator: 'llf123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '玉米',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/呼伦贝尔市/满洲里市',
      p2: '江苏/南通市/崇川区',
      p3: 1500,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '凯江物流',
      p8: '土豆',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2019-02-14 09:40:46',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2082',
      p16: '0',
      createdate1: {},
      p15: 'llf123',
      p18: '8-10米',
      p17: '9.6',
      p19: {},
      id: 9327,
      creator: 'llf123',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '土豆',
      name2: {},
      name1: {},
    },
    {
      p1: '黑龙江/鸡西市/鸡冠区',
      p2: '河北/石家庄市/长安区',
      p3: 2000,
      p4: '500-3000kg',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '速速快运',
      p8: '大米',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-02-13 14:33:42',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '8-10米',
      p17: '9.6',
      p19: {},
      id: 9326,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '大米',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/赤峰市/红山区',
      p2: '内蒙古/通辽市/科尔沁区',
      p3: 10000,
      p4: '1000kg以上',
      p5: 1000,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '重货',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-02-13 09:26:15',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '8-10米',
      p17: '9.6',
      p19: {},
      id: 9325,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '重货',
      name2: {},
      name1: {},
    },
    {
      p1: '吉林/白山市/浑江区',
      p2: '河南/郑州市/中原区',
      p3: 3000,
      p4: '1000kg以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '钢材',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-02-13 09:22:42',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9324,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '钢材',
      name2: {},
      name1: {},
    },
    {
      p1: '陕西/咸阳市/杨凌区',
      p2: '河南/驻马店市/遂平县',
      p3: 1.08,
      p4: '0-100kg',
      p5: 1,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '张斌',
      p8: '饲料',
      bigfield: {},
      p9: 42,
      istms: {},
      p10: '2019-01-27 09:16:23',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3447',
      p16: '0',
      createdate1: {},
      p15: 'zb727627530',
      p18: {},
      p17: '',
      p19: {},
      id: 9323,
      creator: 'zb727627530',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '饲料',
      name2: {},
      name1: {},
    },
    {
      p1: '四川/成都市/邛崃市',
      p2: '河南/洛阳市/偃师市',
      p3: 200,
      p4: '200-500kg',
      p5: 4,
      ckstatus: {},
      p6: '2-5立方米',
      p7: '成都通丰环保防腐设备有限公司',
      p8: '设备',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2019-01-25 13:34:51',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3454',
      p16: '0',
      createdate1: {},
      p15: 'CDTFHB',
      p18: {},
      p17: '',
      p19: {},
      id: 9320,
      creator: 'CDTFHB',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '设备',
      name2: {},
      name1: {},
    },
    {
      p1: '浙江/杭州市/上城区',
      p2: '上海/上海市/黄浦区',
      p3: 500,
      p4: '500-1000kg',
      p5: 50,
      ckstatus: {},
      p6: '40-70立方米',
      p7: '国际物流',
      p8: '书本',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-01-21 13:41:13',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '8-10米',
      p17: '9.6',
      p19: {},
      id: 9318,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '书本',
      name2: {},
      name1: {},
    },
    {
      p1: '黑龙江/哈尔滨市/道里区',
      p2: '山东/济南市/历下区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '家具',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-01-21 13:40:03',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9317,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
  ],

  '6': [
    {
      p1: '河北/石家庄市/长安区',
      p2: '江苏/南京市/玄武区',
      p3: 1500,
      p4: '1000kg以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '空调',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2019-01-21 13:36:59',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9316,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '空调',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/东城区',
      p2: '广西/南宁市/兴宁区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '国际物流',
      p8: '沙发',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2019-01-21 13:35:19',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '2085',
      p16: '0',
      createdate1: {},
      p15: 'zhangshaoze',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9315,
      creator: 'zhangshaoze',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '沙发',
      name2: {},
      name1: {},
    },
    {
      p1: '四川/成都市/锦江区',
      p2: '浙江/金华市/婺城区',
      p3: 3000,
      p4: '18吨',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '柜子',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-01-15 11:06:24',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9314,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '柜子',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/开封市/龙亭区',
      p2: '江苏/南京市/玄武区',
      p3: 1000,
      p4: '38吨',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '粮食',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-01-15 11:01:03',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9313,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/东城区',
      p2: '天津/天津市/河西区',
      p3: 1500,
      p4: '38吨',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '自行车',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2019-01-15 10:54:24',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9312,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '自行车',
      name2: {},
      name1: {},
    },
    {
      p1: '广东/广州市/荔湾区',
      p2: '上海/上海市/黄浦区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '鞋架',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-01-15 10:52:38',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9311,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '鞋架',
      name2: {},
      name1: {},
    },
    {
      p1: '辽宁/沈阳市/和平区',
      p2: '黑龙江/哈尔滨市/道里区',
      p3: 500,
      p4: '500-1000kg',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '速速快运',
      p8: '大米',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-01-07 16:48:02',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9310,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '大米',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/南京市/玄武区',
      p2: '浙江/杭州市/上城区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '装饰材料',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-01-07 16:47:10',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9309,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '装饰材料',
      name2: {},
      name1: {},
    },
    {
      p1: '上海/上海市/黄浦区',
      p2: '广东/深圳市/罗湖区',
      p3: 2500,
      p4: '1000kg以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '自行车',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-01-07 16:18:06',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9308,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '自行车',
      name2: {},
      name1: {},
    },
    {
      p1: '山东/青岛市/市南区',
      p2: '河南/开封市/龙亭区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '鞋',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-01-07 14:26:16',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9307,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '鞋',
      name2: {},
      name1: {},
    },
  ],

  '7': [
    {
      p1: '四川/成都市/锦江区',
      p2: '河北/唐山市/路南区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '配件',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2019-01-03 09:13:57',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9306,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '配件',
      name2: {},
      name1: {},
    },
    {
      p1: '广东/广州市/荔湾区',
      p2: '山西/太原市/小店区',
      p3: 1500,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '粮食',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2019-01-03 09:12:32',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9305,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '山东/济南市/历下区',
      p2: '黑龙江/哈尔滨市/道里区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '装饰材料',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2019-01-03 09:06:54',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9304,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '装饰材料',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/东城区',
      p2: '广西/南宁市/兴宁区',
      p3: 2000,
      p4: '1000kg以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '家具',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2019-01-03 09:01:18',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9302,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '上海/上海市/黄浦区',
      p2: '天津/天津市/河东区',
      p3: 1500,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '冰箱',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2018-12-27 14:51:24',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9301,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '冰箱',
      name2: {},
      name1: {},
    },
    {
      p1: '山东/济南市/历下区',
      p2: '广东/深圳市/罗湖区',
      p3: 2000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '衣柜',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2018-12-27 14:49:32',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9300,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '衣柜',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/石家庄市/长安区',
      p2: '江苏/徐州市/鼓楼区',
      p3: 500,
      p4: '500-1000kg',
      p5: 50,
      ckstatus: {},
      p6: '40-70立方米',
      p7: '速速快运',
      p8: '坚果',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2018-12-27 14:47:25',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9299,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '坚果',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/唐山市/路南区',
      p2: '江西/南昌市/东湖区',
      p3: 1000,
      p4: '21吨',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '食品',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-26 13:47:17',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9298,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '食品',
      name2: {},
      name1: {},
    },
    {
      p1: '广西/南宁市/兴宁区',
      p2: '天津/天津市/和平区',
      p3: 2000,
      p4: '1000kg以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '面粉',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-26 13:35:58',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9297,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '面粉',
      name2: {},
      name1: {},
    },
    {
      p1: '黑龙江/哈尔滨市/道里区',
      p2: '吉林/长春市/南关区',
      p3: 500,
      p4: '500-1000kg',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '速速快运',
      p8: '家具',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2018-12-25 09:11:09',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9296,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
  ],

  '8': [
    {
      p1: '广西/柳州市/城中区',
      p2: '河北/石家庄市/长安区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '图书',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2018-12-25 09:09:56',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9295,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '图书',
      name2: {},
      name1: {},
    },
    {
      p1: '辽宁/沈阳市/和平区',
      p2: '北京/北京市/丰台区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '粮食',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-25 09:09:05',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9294,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '吉林/长春市/南关区',
      p2: '黑龙江/哈尔滨市/道里区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '家具',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2018-12-20 10:42:17',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9293,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/开封市/顺河回族区',
      p2: '江苏/南京市/玄武区',
      p3: 1000,
      p4: '500-3000kg',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '米',
      bigfield: {},
      p9: 200,
      istms: {},
      p10: '2018-12-20 10:41:18',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9292,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '米',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/东城区',
      p2: '山东/青岛市/市北区',
      p3: 500,
      p4: '500-1000kg',
      p5: 30,
      ckstatus: {},
      p6: '20-40立方米',
      p7: '速速快运',
      p8: '大枣',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2018-12-20 10:40:34',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '8-10米',
      p17: '9',
      p19: {},
      id: 9291,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '大枣',
      name2: {},
      name1: {},
    },
    {
      p1: '宁夏/银川市/兴庆区',
      p2: '贵州/贵阳市/南明区',
      p3: 1000,
      p4: '500-3000kg',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '大米',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-18 10:51:02',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9290,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '大米',
      name2: {},
      name1: {},
    },
    {
      p1: '湖北/武汉市/江岸区',
      p2: '江苏/南京市/玄武区',
      p3: 2000,
      p4: '36吨以上',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '沙子',
      bigfield: {},
      p9: 30,
      istms: {},
      p10: '2018-12-18 10:50:21',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9289,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '沙子',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/开封市/龙亭区',
      p2: '上海/上海市/黄浦区',
      p3: 500,
      p4: '500-1000kg',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '速速快运',
      p8: '水果',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-17 15:35:19',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '8-10米',
      p17: '9',
      p19: {},
      id: 9288,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '水果',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/东城区',
      p2: '吉林/长春市/南关区',
      p3: 1000,
      p4: '36吨以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '家具',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2018-12-17 15:10:18',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9287,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '广西/北海市/合浦县',
      p2: '山东/潍坊市/青州市',
      p3: 8000,
      p4: '1000kg以上',
      p5: 10,
      ckstatus: {},
      p6: '10-20立方米',
      p7: 'taxuewuhen',
      p8: '食品',
      bigfield: {},
      p9: 400,
      istms: {},
      p10: '2018-12-12 14:07:27',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3293',
      p16: '0',
      createdate1: {},
      p15: 'taxuewuhen',
      p18: {},
      p17: '',
      p19: {},
      id: 9286,
      creator: 'taxuewuhen',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '食品',
      name2: {},
      name1: {},
    },
  ],

  '9': [
    {
      p1: '山东/东营市/东营区',
      p2: '上海/上海市/黄浦区',
      p3: 500,
      p4: '500-1000kg',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '速速快运',
      p8: '蔬菜',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2018-12-12 08:57:17',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9285,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '蔬菜',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/包头市/东河区',
      p2: '吉林/吉林市/昌邑区',
      p3: 1000,
      p4: '30吨以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '柜子',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2018-12-12 08:56:16',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9284,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '柜子',
      name2: {},
      name1: {},
    },
    {
      p1: '黑龙江/哈尔滨市/道里区',
      p2: '辽宁/沈阳市/和平区',
      p3: 1000,
      p4: '30吨以上',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '设备',
      bigfield: {},
      p9: 30,
      istms: {},
      p10: '2018-12-11 09:20:52',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9283,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '设备',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/无锡市/崇安区',
      p2: '四川/成都市/锦江区',
      p3: 500,
      p4: '500-1000kg',
      p5: 30,
      ckstatus: {},
      p6: '20-40立方米',
      p7: '速速快运',
      p8: '配件',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2018-12-11 09:19:51',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '10-14米',
      p17: '13',
      p19: {},
      id: 9282,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '配件',
      name2: {},
      name1: {},
    },
    {
      p1: '山东/济南市/历下区',
      p2: '辽宁/沈阳市/和平区',
      p3: 1000,
      p4: '30吨以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '服装',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-10 15:59:49',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9281,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '服装',
      name2: {},
      name1: {},
    },
    {
      p1: '北京/北京市/东城区',
      p2: '江苏/南京市/玄武区',
      p3: 2000,
      p4: '21吨',
      p5: 400,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '床',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2018-12-10 15:57:25',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9280,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '床',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/沧州市/青县',
      p2: '山东/菏泽市/曹县',
      p3: 3500,
      p4: '1000kg以上',
      p5: 3.5,
      ckstatus: {},
      p6: '2-5立方米',
      p7: '真旺',
      p8: '牛奶',
      bigfield: {},
      p9: 600,
      istms: {},
      p10: '2018-12-07 11:18:46',
      p12: '允许',
      p11: '',
      p14: 0,
      p13: '3258',
      p16: '0',
      createdate1: {},
      p15: 'Tia8677',
      p18: {},
      p17: '',
      p19: {},
      id: 9279,
      creator: 'Tia8677',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '牛奶',
      name2: {},
      name1: {},
    },
    {
      p1: '山东/滨州市/滨城区',
      p2: '湖北/黄石市/大冶市',
      p3: 12000,
      p4: '1000kg以上',
      p5: 20,
      ckstatus: {},
      p6: '20-40立方米',
      p7: '王文鹏',
      p8: '杂货',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-06 10:41:51',
      p12: '不允许',
      p11: '18986756808联系电话',
      p14: 0,
      p13: '3251',
      p16: '0',
      createdate1: {},
      p15: 'tkyrha',
      p18: '8-10米',
      p17: '9',
      p19: {},
      id: 9278,
      creator: 'tkyrha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '杂货',
      name2: {},
      name1: {},
    },
    {
      p1: '江苏/南京市/玄武区',
      p2: '山西/大同市/新荣区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '沙发',
      bigfield: {},
      p9: 10,
      istms: {},
      p10: '2018-12-06 09:30:20',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '18米以上',
      p17: '21',
      p19: {},
      id: 9277,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '沙发',
      name2: {},
      name1: {},
    },
    {
      p1: '河南/郑州市/中原区',
      p2: '重庆/重庆市/万州区',
      p3: 500,
      p4: '500-1000kg',
      p5: 200,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '核桃',
      bigfield: {},
      p9: 200,
      istms: {},
      p10: '2018-12-06 09:29:40',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9276,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '核桃',
      name2: {},
      name1: {},
    },
  ],

  '10': [
    {
      p1: '河南/郑州市/中原区',
      p2: '江苏/南京市/玄武区',
      p3: 500,
      p4: '500-1000kg',
      p5: 100,
      ckstatus: {},
      p6: '70-150立方米',
      p7: '速速快运',
      p8: '坚果',
      bigfield: {},
      p9: 200,
      istms: {},
      p10: '2018-12-05 11:14:19',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '8-10米',
      p17: '9',
      p19: {},
      id: 9275,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '坚果',
      name2: {},
      name1: {},
    },
    {
      p1: '广西/南宁市/兴宁区',
      p2: '黑龙江/哈尔滨市/道里区',
      p3: 1000,
      p4: '18吨',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '普货',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2018-12-05 11:11:32',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9274,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '普货',
      name2: {},
      name1: {},
    },
    {
      p1: '河北/石家庄市/藁城区',
      p2: '北京/北京市/西城区',
      p3: 1500,
      p4: '18吨',
      p5: 500,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '服装',
      bigfield: {},
      p9: 50,
      istms: {},
      p10: '2018-12-04 10:29:14',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9273,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '服装',
      name2: {},
      name1: {},
    },
    {
      p1: '宁夏/银川市/兴庆区',
      p2: '四川/攀枝花市/东区',
      p3: 600,
      p4: '500-1000kg',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '家电',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2018-12-03 15:28:38',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9272,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家电',
      name2: {},
      name1: {},
    },
    {
      p1: '福建/福州市/鼓楼区',
      p2: '江苏/南京市/玄武区',
      p3: 500,
      p4: '500-1000kg',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '家具',
      bigfield: {},
      p9: 20,
      istms: {},
      p10: '2018-12-03 15:26:29',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '14',
      p19: {},
      id: 9271,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '家具',
      name2: {},
      name1: {},
    },
    {
      p1: '广东/广州市/荔湾区',
      p2: '河北/沧州市/新华区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '粮食',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2018-12-03 15:24:37',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9270,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '四川/成都市/成华区',
      p2: '上海/上海市/浦东新区',
      p3: 250,
      p4: '200-500kg',
      p5: 1.2,
      ckstatus: {},
      p6: '1-2立方米',
      p7: '北京美丽牧场餐饮管理有限公司',
      p8: '铁板烧',
      bigfield: {},
      p9: 1,
      istms: {},
      p10: '2018-11-30 12:19:58',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3208',
      p16: '0',
      createdate1: {},
      p15: 'a17623121919',
      p18: {},
      p17: '',
      p19: {},
      id: 9269,
      creator: 'a17623121919',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '铁板烧',
      name2: {},
      name1: {},
    },
    {
      p1: '四川/攀枝花市/东区',
      p2: '浙江/嘉兴市/南湖区',
      p3: 1500,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '粮食',
      bigfield: {},
      p9: 100,
      istms: {},
      p10: '2018-11-29 08:57:00',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9268,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '粮食',
      name2: {},
      name1: {},
    },
    {
      p1: '内蒙古/鄂尔多斯市/东胜区',
      p2: '天津/天津市/蓟州区',
      p3: 14000,
      p4: '1000kg以上',
      p5: 14,
      ckstatus: {},
      p6: '10-20立方米',
      p7: '倪望青',
      p8: '净水剂',
      bigfield: {},
      p9: 475,
      istms: {},
      p10: '2018-11-28 14:56:11',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '3196',
      p16: '0',
      createdate1: {},
      p15: 'nelsonni',
      p18: {},
      p17: '',
      p19: {},
      id: 9267,
      creator: 'nelsonni',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '净水剂',
      name2: {},
      name1: {},
    },
    {
      p1: '吉林/吉林市/昌邑区',
      p2: '四川/成都市/锦江区',
      p3: 1000,
      p4: '1000kg以上',
      p5: 300,
      ckstatus: {},
      p6: '150立方米以上',
      p7: '速速快运',
      p8: '普货',
      bigfield: {},
      p9: 500,
      istms: {},
      p10: '2018-11-28 10:20:01',
      p12: '不允许',
      p11: '',
      p14: 0,
      p13: '130',
      p16: '0',
      createdate1: {},
      p15: 'liqingsha',
      p18: '14-18米',
      p17: '17',
      p19: {},
      id: 9265,
      creator: 'liqingsha',
      p21: {},
      p20: {},
      p23: {},
      p22: {},
      p25: {},
      p24: {},
      iscapture: '0',
      issuccess: {},
      t: {},
      p26: {},
      name: '普货',
      name2: {},
      name1: {},
    },
  ],
};
export default httpDataList;
