import React from 'react';
import { Row, Col, Input, Button, Checkbox } from 'antd';
import './index.less';

export default class Register extends React.Component {
  state = {};
  constructor(props) {
    super(props);
  }

  submitData() {
    let userName = document.getElementById('userName').value;
    let pwd = document.getElementById('pwd').value;
    let pwd2 = document.getElementById('pwd2').value;
    let phone = document.getElementById('phone').value;
    let checkboxBtn = document.getElementById('checkboxBtn');
    if (userName === '') {
      alert('请输入用户名');
      return;
    }

    if (pwd === '') {
      alert('请输入密码');
      return;
    }
    if (pwd.length < 6) {
      alert('请输入6位以上密码');
      return;
    }

    if (pwd2 === '') {
      alert('请输入确认密码');
      return;
    }

    if (phone === '') {
      alert('请输入手机号');
      return;
    }

    if (pwd !== pwd2) {
      alert('两次密码不一致');
      return;
    }

    if (!/^1[3456789]\d{9}$/.test(phone)) {
      alert('手机号码有误，请重填');
      return false;
    }

    let localStorage = window.localStorage.getItem('_i_');
    if (localStorage) {
      let obj = JSON.parse(localStorage);
      let phoneExists = obj.findIndex((v) => v.phone === phone);
      if (phoneExists !== -1) {
        alert('此手机号已注册');
        return;
      }
      obj.push({ u: userName, p: pwd, phone: phone });
      window.localStorage.setItem('_i_', JSON.stringify(obj));
    } else {
      window.localStorage.setItem(
        '_i_',
        JSON.stringify([{ u: userName, p: pwd, phone: phone }])
      );
    }
    alert('注册成功');
    window.location = '#/login';
  }

  componentWillMount() {}

  render() {
    const { value1, value2 } = this.state;
    return (
      <div style={{ width: '100%', height: '800px' }}>
        <Row
          style={{
            padding: '10px',
            width: '700px',
            margin: '0 auto',
            marginTop: '150px',
            lineHeight: '35px',
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <span style={{ color: 'red' }}>*</span>用户名：
          </Col>
          <Col span={16}>
            <input
              id="userName"
              className="ant-input s-input"
              placeholder="您的账户名和登录名"
            ></input>
          </Col>
        </Row>
        <Row
          style={{
            padding: '15px',
            width: '700px',
            margin: '0px auto',
            lineHeight: '30px',
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <span style={{ color: 'red' }}>*</span>设置密码：
          </Col>
          <Col span={16}>
            <input
              id="pwd"
              type="password"
              className="ant-input s-input"
              placeholder="建议密码位以上"
            ></input>
          </Col>
        </Row>
        <Row
          style={{
            padding: '15px',
            width: '700px',
            margin: '0px auto',
            lineHeight: '30px',
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <span style={{ color: 'red' }}>*</span>确认密码：
          </Col>
          <Col span={16}>
            <input
              id="pwd2"
              type="password"
              className="ant-input s-input"
              placeholder="请再次输入密码"
            ></input>
          </Col>
        </Row>
        <Row
          style={{
            padding: '15px',
            width: '700px',
            margin: '0px auto',
            lineHeight: '30px',
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <span style={{ color: 'red' }}>*</span>手机号码：
          </Col>
          <Col span={16}>
            <input
              id="phone"
              className="ant-input s-input"
              placeholder="建议使用常用手机"
            ></input>
          </Col>
        </Row>
        <Row
          style={{
            padding: '15px',
            width: '700px',
            margin: '0px auto',
            textAlign: 'center',
            lineHeight: '30px',
          }}
        >
          <Col span={20}>
            <input id="checkboxBtn" type="checkbox" checked="checked"></input>
            <span>我已阅读</span>
            <a href="#/agreement">《服务协议》</a>
          </Col>
        </Row>
        <Row
          style={{
            padding: '15px',
            width: '700px',
            margin: '0px auto',
            textAlign: 'center',
            lineHeight: '30px',
          }}
        >
          <Col span={20}>
            <input
              type="button"
              style={{ height: '42px' }}
              id="tijiao"
              className="mywuda_sub_18"
              value="同意条款并注册"
              onClick={() => {
                this.submitData();
              }}
            ></input>
          </Col>
        </Row>
      </div>
    );
  }
}
