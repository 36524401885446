import React from 'react';
import { Row, Col } from 'antd';
import './index.less';
import arrow from '../../img/solution/arrow.png';
import arrow2 from '../../img/solution/arrow2.png';

export default class order extends React.Component {
  constructor(props) {
    super(props);
  }
  handleClick(info) {
    if (info === 'bottomjava') {
      document.getElementById('bottomjava').className = 'hiden';
      document.getElementById('leftjava').className = 'show';
      document.getElementById('java-describe').className = 'job-describe hiden';
    } else if (info === 'leftjava') {
      document.getElementById('bottomjava').className = 'show';
      document.getElementById('leftjava').className = 'hiden';
      document.getElementById('java-describe').className = 'job-describe show';
    } else if (info === 'leftfront') {
      document.getElementById('bottomfront').className = 'show';
      document.getElementById('leftfront').className = 'hiden';
      document.getElementById('front-describe').className = 'job-describe show';
    } else {
      document.getElementById('leftfront').className = 'show';
      document.getElementById('bottomfront').className = 'hiden';
      document.getElementById('front-describe').className =
        'job-describe hiden';
    }
  }

  getCurrentDate() {
    // 获取当前日期
    var date = new Date();

    // 获取当前月份
    var nowMonth = date.getMonth() + 1;

    // 获取当前是几号
    var strDate = date.getDate();

    // 添加分隔符“-”
    var seperator = '-';

    // 对月份进行处理，1-9月在前面添加一个“0”
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = '0' + nowMonth;
    }

    // 对月份进行处理，1-9号在前面添加一个“0”
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate;
    }

    // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
    var nowDate =
      date.getFullYear() + seperator + nowMonth + seperator + strDate;
    return nowDate;
  }
  getUrlParams(name) {
    // 不传name返回所有值，否则返回对应值
    var url = window.location.href;
    if (url.indexOf('?') == -1) {
      return false;
    }
    url = url.substr(url.indexOf('?') + 1);
    url = url.split('&');
    var name = name || '';
    var nameres;
    // 获取全部参数及其值
    for (var i = 0; i < url.length; i++) {
      var info = url[i].split('=');
      var obj = {};
      obj[info[0]] = decodeURI(info[1]);
      url[i] = obj;
    }
    // 如果传入一个参数名称，就匹配其值
    if (name) {
      for (var i = 0; i < url.length; i++) {
        for (const key in url[i]) {
          if (key == name) {
            nameres = url[i][key];
          }
        }
      }
    } else {
      nameres = url;
    }
    // 返回结果
    return nameres;
  }

  jiedan() {
    let cys = document.getElementById('cys');
    let sj = document.getElementById('sj');
    let lxdh = document.getElementById('lxdh');
    if (!cys.value) {
      alert('请输入承运商名称');
      return;
    }
    if (!sj.value) {
      alert('请输入司机姓名');
      return;
    }
    if (!lxdh.value) {
      alert('请输入联系电话');
      return;
    }
    setTimeout(() => {
      alert('您的信息已提交，请等待货主联系。谢谢');
    }, 500);
  }

  render() {
    var res1 = this.getUrlParams('id');
    let fhList = [
      {
        name: '刘先生',
        goods: '饲料',
        city: '天津',
        endCity: '成都',
        weight: '6~10吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '张先生',
        goods: '压缩机',
        city: '天津',
        endCity: '广州',
        weight: '8~22吨',
        car: '平板',
        carLength: '9.6米',
        carDate: '2020-08-10',
      },
      {
        name: '王先生',
        goods: '百货',
        city: '鞍山',
        endCity: '温州',
        weight: '4吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '李先生',
        goods: '空压机及配件',
        city: '衢州市',
        endCity: '上海',
        weight: '6~8吨',
        car: '高栏',
        carLength: '6.2米',
        carDate: '2020-08-10',
      },
      {
        name: '李先生',
        goods: '封头铁板',
        city: '衢州市',
        endCity: '珠海',
        weight: '25~25吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '严经理',
        goods: '饮料',
        city: '成都市',
        endCity: '宜宾',
        weight: '2.7吨',
        car: '高栏',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
      {
        name: '黄先生',
        goods: '钢材',
        city: '佛山市',
        endCity: '商丘市',
        weight: '31.5吨',
        car: '高栏车',
        carLength: '12.5-13米',
        carDate: '2020-08-10',
      },
      {
        name: '魏经理',
        goods: '面粉',
        city: '河南省-周口市',
        endCity: '济南市',
        weight: '32吨',
        car: '整车配货',
        carLength: '9米6',
        carDate: '2020-08-10',
      },
      {
        name: '梁',
        goods: '建材',
        city: '河北省-保定市',
        endCity: '天津市',
        weight: '1.5吨',
        car: '整车配货',
        carLength: '箱式',
        carDate: '2020-08-10',
      },
      {
        name: '龚师傅',
        goods: '日用百货',
        city: '南通市',
        endCity: '南京市',
        weight: '7.4吨',
        car: '整车配货',
        carLength: '6.2米',
        carDate: '2020-08-10',
      },
      {
        name: '衡水市瑞飞运输有限公司',
        goods: '设备',
        city: '衡水',
        endCity: '运城市',
        weight: '32吨',
        car: '整车配货',
        carLength: '12.5米',
        carDate: '2020-08-10',
      },
    ];

    return (
      <div style={{ width: '100%' }}>
        <Row className="join-box"></Row>
        <Row style={{ marginBottom: '30px' }}>
          <div className="goods-container">
            <Row>
              <Col span={3} className="g-title">
                货主：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].name}
              </Col>
              <Col span={3} className="g-title">
                货物名称：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].goods}
              </Col>
            </Row>
            <Row>
              <Col span={3} className="g-title">
                发货地：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].city}
              </Col>
              <Col span={3} className="g-title">
                到达地：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].endCity}
              </Col>
            </Row>
            <Row>
              <Col span={3} className="g-title">
                重量：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].weight}
              </Col>
              <Col span={3} className="g-title">
                车型要求：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].car}
              </Col>
            </Row>
            <Row>
              <Col span={3} className="g-title">
                车长要求：
              </Col>
              <Col span={9} className="t-c">
                {fhList[res1].carLength}
              </Col>
              <Col span={3} className="g-title">
                装货日期：
              </Col>
              <Col span={9} className="t-c">
                {this.getCurrentDate()}
              </Col>
            </Row>
          </div>
          {/* <div className="goods-container">
            <Row>
              <Col span={2} className="g-title">
                承运商名称
              </Col>
              <Col span={10}>
                <input id="cys"></input>
              </Col>
              <Col span={2} className="g-title">
                司机姓名
              </Col>
              <Col span={10}>
                <input id="sj"></input>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col span={2} className="g-title">
                联系电话
              </Col>
              <Col span={10}>
                <input id="lxdh"></input>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col span={20} style={{ textAlign: 'center' }}>
                <button
                  style={{
                    border: '1px solid #fa841e',
                    borderRadius: '4px',
                    color: '#fa841e',
                    padding: '8px 60px',
                    backgroundColor: '#fff',
                  }}
                  onClick={this.jiedan}
                >
                  接单
                </button>
              </Col>
            </Row>
          </div> */}
        </Row>
      </div>
    );
  }
}
