import React, { Fragment } from "react";
import { Row, Col, Input, notification, Icon } from "antd";
import rhicpImg from "../../img/homeYMYT/rhicp.png";
import "./less/content50.less";
import Content6 from "./Content6";
const { Search } = Input;

class Content5 extends React.PureComponent {
  searchOrderNo = value => {
    if (document.body.clientWidth < 767) {
      window.open(
        "https://search.youmila.com/?orderno=" + value,
        "target",
        ""
      );
    } else {
      window.open(
        "https://search.youmila.com?orderSearch=" + value,
        "target",
        ""
      );
    }
  };
  render() {
    const openNotification = () => {
      const args = {
        message: "400-698-5656",
        description: "详情请拨打电话 400-698-5656,欢迎来电咨询",
        duration: 3,
        icon: <Icon type="phone" style={{ color: "#108ee9" }} />
      };
      notification.open(args);
    };
    return (
      <Fragment>
        <div className="main-container">
          <Row type="flex" justify="space-between">
            <Col md={{ span: 17 }} xs={{ span: 24 }}>
              <div className="prod-container">
                <Row type="flex" justify="space-between">
                  <Col
                    className="prod-item-box"
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="prod-item">
                      <div className="prod-item-hover prod-item-hover"></div>
                      <p>E-TMS系统</p>
                      <span>三方物流系统</span>
                    </div>
                  </Col>

                  <Col
                    className="prod-item-box"
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="prod-item">
                      <div className="prod-item-hover prod-item-hover2"></div>
                      <p>E-OMS系统</p>
                      <span>专业的订单管理系统</span>
                    </div>
                  </Col>

                  <Col
                    className="prod-item-box"
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="prod-item">
                      <div className="prod-item-hover prod-item-hover3"></div>
                      <p>E-WMS系统</p>
                      <span>专业的仓储管理系统</span>
                    </div>
                  </Col>

                  <Col
                    className="prod-item-box"
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="prod-item">
                      <div className="prod-item-hover prod-item-hover4"></div>
                      <p>物流助推</p>
                      <span>下单查单的好帮手</span>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="addv-container">
                <p>增值服务</p>
                <Row type="flex" justify="center">
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover"></div>
                      <span>企业融资</span>
                    </div>
                  </Col>
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover2"></div>
                      <span>优惠加油</span>
                    </div>
                  </Col>
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover3"></div>
                      <span>优质货源</span>
                    </div>
                  </Col>
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover4"></div>
                      <span>扫码支付</span>
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="center">
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover5"></div>
                      <span>整车服务</span>
                    </div>
                  </Col>
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover6"></div>
                      <span>定位服务</span>
                    </div>
                  </Col>
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover7"></div>
                      <span>ETC优惠卡</span>
                    </div>
                  </Col>
                  <Col
                    className="addv-item-box"
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <div className="addv-item">
                      <div className="addv-item-hover addv-item-hover8"></div>
                      <span>保价运输</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={{ span: 6 }} xs={{ span: 24 }} className="right-container">
              <div className="search_text">
                <Search
                  placeholder="请输入运单号查询"
                  onSearch={this.searchOrderNo}
                />
              </div>
              <Row className="portal">
                <Col
                  span={24}
                  onClick={() => {
                    window.open("http://oms.youmila.com", "target", "");
                  }}
                >
                  货主发货入口
                </Col>
                <Col
                  span={24}
                  onClick={() => {
                    window.open("http://tms.youmila.com", "target", "");
                  }}
                >
                  物流公司入口
                </Col>
                <Col span={24} onClick={openNotification}>
                  运营中心入口
                </Col>
              </Row>
              {/* <div className="qrcode gutter-example">
                <Row type="flex" justify="space-between">
                  <Col span={12}>
                    <div className="app1"></div>
                    <p>司机APP</p>
                  </Col>
                  <Col span={12}>
                    <div className="app2"></div>
                    <p>客户APP</p>
                  </Col>
                </Row>
                <Row type="flex" justify="space-between">
                  <Col span={12}>
                    <div className="app3"></div>
                    <p>承运商APP</p>
                  </Col>
                  <Col span={12}>
                    <div className="app4"></div>
                    <p>物流助推</p>
                  </Col>
                </Row>
              </div> */}
            </Col>
          </Row>
        </div>
        <Content6></Content6>
        <div className="rhicp-container">
          <img src={rhicpImg} className="rhicp-img" />
        </div>
      </Fragment>
    );
  }
}

export default Content5;
