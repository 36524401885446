import React, { Fragment } from "react";
import { Row, Col, Input, notification, Icon } from "antd";
import rhicpImg from "../../img/homeYMYT/rhicp.png";
import "./less/content6.less";
import httpDataList from '../Forwarder/httpData';

const { Search } = Input;

class Content6 extends React.PureComponent {
  state = {
    httpDataList: [],
  };
  componentWillMount() {
    this.refresh()
  }
  refresh(){
    let dataList = [
      {
        idd:0,
        pic: this.state.img1,
        name: '东莞市金玛物流有限公司',
        line: '东莞 → 上海',
        desc:
          '介绍：东莞市金玛物流有限公司承接东莞至全国整车零担货物运输，自备4.2米到17.5米大小车',
        address:
          '地址：一部地址：东莞市大朗镇康丰路巷头社区大朗物流中心三期C号',
        time: '2天',
        money: '面议',
        dengji: '2',
      },
      {
        idd:1,
        pic: this.state.img2,
        name: '东莞市菜鸟仓储物流有限公司',
        line: '北京 → 广州',
        desc:
          '简介：东莞市菜鸟仓储物流有限公司（菜鸟智能物流）承接广东珠三角地区到全国各地整车零担货物运',
        address:
          '地址一：东莞市高步镇北王路百茂物流园',
        time: '3天',
        money: '1400元',
        dengji: '3',
      },
      {
        idd:2,
        pic: this.state.img3,
        name: '广州创盛物流有限公司',
        line: '广州 → 上海',
        desc:
          '简介：广州创盛物流有限公司，自成立以来以优先的技术专业，严格的操作安全，自强不息的严格管理',
        address: '地址：广州市白云区龙陂口北街锦邦货运市场',
        time: '1天',
        money: '面议',
        dengji: '2',
      },
      {
        idd:3,
        pic: this.state.img4,
        name: '江西威凯物流有限公司广州业务部',
        line: '广州 → 上海',
        desc:
          '简介：江西威凯物流有限公司广州业务部为您提供零担运输、整车运输等服务。运营及合作货运专线覆',
        address: '地址：广州市大源北路林安物流园物联网中心6楼613室',
        time: '2天',
        money: '面议',
        dengji: '4',
      },
      {
        idd:4,
        pic: this.state.img5,
        name: '天津市百利东升物流有限公司',
        line: '天津 → 郑州',
        desc:
          '简介：【天津市百利东升物流有限公司】是一家长途运输公司物流网络覆盖全国大中城市承接天津至全',
        address: '地址：天津市北辰区佳丰道华北集团成邦物流园',
        time: '2天',
        money: '面议',
        dengji: '2',
      },
      {
        idd:5,
        pic: this.state.img6,
        name: '天津宏泰金通物流有限公司',
        line: '天津 → 河南',
        desc: '简介：主营：天津至河南、广东、上海直达专线；   天津到河南专线：',
        address: '地址：天津市北辰区红旗路北兴物流园内',
        time: '3天',
        money: '面议',
        dengji: '3',
      },
      {
        idd:6,
        pic: this.state.img7,
        name: '山西转型综改示范区阳曲园区京大货运部',
        line: '太原 → 北京',
        desc:
          '简介：太原市俊文货运中心货运中心承接全国各地整车、零担运输、轿车运输、长途搬家、包装等业务',
        address: '地址：山西综改示范区太原阳曲园区松庄路2号院南门1-3号库',
        time: '4天',
        money: '面议',
        dengji: '4',
      },
    ];
    let id = Math.floor(Math.random() * 10 + 1);
    let arr=httpDataList[`${id}`];
    arr.forEach((item,index)=>{
      let s=Math.floor(Math.random() * 6 + 1)
      console.log(s)
      // item.forwarder=dataList[s].name
      item=Object.assign(item,dataList[s])
    })
    console.log(arr)

    this.setState({ httpDataList: arr });
  }
  gotoOrder(item){
    window.localStorage.setItem('_dr_',JSON.stringify(item));
    window.location.href='#/fDetailhw?id='+item.idd
  }
  render() {
    return (
      <Fragment>
        <div className="c6-warper">
          <div className="c6-header">
            <div>热门货源</div>
            <div onClick={()=>{this.refresh()}}>
              <span className="shuaxin"></span>
              <span>换一批</span>
            </div>
          </div>
          <div className="b-warper">
            {
              this.state.httpDataList.map((item)=>(
              <div className="block-h" onClick={()=>{this.gotoOrder(item)}}>
                <div className="ma_host_city_l_c_t">
                 <span>{item.p1.split('/')[0]}</span> <em></em> <span>{item.p2.split('/')[0]}</span>
                 <i class="host_city_blue">直达</i>
                </div>
                <div className="ma_host_city_l_c_m">
                  <span>{`${item.p1.split('/')[1]}-${item.p1.split('/')[2]}`}</span>
                  <span class="host_city_last">{`${item.p2.split('/')[1]}-${item.p2.split('/')[2]}`}</span>
                  <div class="cleardiv"></div>
                  <p class="host_city_pri">
                    <span>
                      <i class="host_city_pri_z"></i>
                      <span>重货<i>{item.p4}</i></span>
                    </span>
                    <span class="host_city_pri_l">
                      <i class="host_city_pri_p"></i>
                      <span>泡货<i>{item.p6}</i></span>
                    </span><em class="clear"></em>
                  </p>
                </div>
                <div class="ma_host_city_l_c_b">
                  <p><em></em>{item.name}</p>
                  <p class="ma_host_be"><span><i class="ma_host_be_pri">价格电议</i></span></p>
                  </div>
              </div>
             ))
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Content6;
