import React from 'react';
import { Row, Col, Input, Button, Checkbox } from 'antd';
import './index.less';

export default class Login extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
    };
  }
  componentDidMount = () => {
    let order = window.localStorage.getItem('_o_');
    if (order) {
      let orders = JSON.parse(order);
      this.setState({ dataList: orders });
    }
  };
  dateFormat(fmt, date) {
    let ret;
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
        );
      }
    }
    return fmt;
  }

  componentWillMount() {}

  render() {
    const { value1, value2 } = this.state;
    return (
      <div style={{ height: '800px', width: '1150px', margin: '0 auto' }}>
        <div className="ve_main_list" style={{ marginTop: '120px' }}>
          <div className="tabn">
            <h1 className="SupplyInfor-cur">我的订单</h1>
          </div>
          <ul
            className="ve_main_list_tit"
            style={{ margin: '0 auto', marginTop: '20px', width: '1250px' }}
          >
            <li className="list_tit_i">货主名称</li>
            <li className="list_tit_j">出发地</li>
            <li className="list_tit_j">到达地</li>
            <li className="list_tit_i">货物名称</li>
            <li className="list_tit_i">货物数量</li>
            <li className="list_tit_i">货物重量</li>
            <li className="list_tit_i">货物体积</li>
            <li className="list_tit_i">车牌</li>
            <li className="list_tit_i">报价</li>
            <li className="list_tit_i" style={{ width: '108px' }}>
              接单时间
            </li>
            {/* <li className="list_tit_c" style={{ borderRight: 'none' }}>
              接单时间
            </li> */}
          </ul>

          <div style={{ clear: 'both' }}></div>
          <div
            className="ve_main_list_bo"
            id="solrcontent"
            style={{ paddingBottom: '20px', width: '1250px' }}
          >
            {this.state.dataList.map((item, index) => (
              <ul
                className={index % 2 === 0 ? 'bg_grey' : 'list_ul'}
                list_ul=""
              >
                <li className="list_tit_i">{item.p7}</li>
                <li className="list_tit_j">{item.p1}</li>
                <li className="list_tit_j">{item.p2}</li>
                <li className="list_tit_i body_li_goodsName" title="">
                  {item.name}
                </li>
                <li className="list_tit_i">
                  <em>{item.p9}</em>件
                </li>
                <li className="list_tit_i">
                  <em>{item.p3}</em>kg
                </li>
                <li className="list_tit_i">
                  <em>{item.p5}</em>m³
                </li>
                <li className="list_tit_i">{item.chepai}</li>
                <li className="list_tit_i">{item.baojia}元</li>
                <li
                  className="list_tit_i"
                  style={{ width: '90px' }}
                >
                  {this.dateFormat(
                    'YYYY-mm-dd HH:MM',
                    new Date()
                  )}
                </li>
                {/* <li className="list_tit_c">
                  {this.dateFormat(
                    'YYYY-mm-dd HH:MM',
                    new Date(item.createDate)
                  )}
                </li> */}
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
