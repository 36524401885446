import React from 'react';
import { Row, Col, Input, Button, Checkbox } from 'antd';
import './index.less';

export default class Login extends React.Component {
  state = {};
  constructor(props) {
    super(props);
  }

  login() {
    if (document.getElementById('tijiao').value === '登录中...') {
      return;
    }
    let account = document.getElementById('account').value;
    let password = document.getElementById('password').value;

    if (account === '') {
      alert('请输入登录名/手机号');
      return;
    }

    if (password === '') {
      alert('请输入密码');
      return;
    }

    let localStorage = window.localStorage.getItem('_i_');
    if (localStorage) {
      let obj = JSON.parse(localStorage);
      let phoneExists = obj.findIndex(
        (v) => v.phone === account || v.u === account
      );
      if (phoneExists === -1) {
        alert('此用户名/手机号不存在，请先注册账号');
        return;
      } else {
        let acc = obj.filter((v) => v.phone === account || v.u === account);

        let p2 = acc.findIndex(
          (v) => (v.phone === account || v.u === account) && v.p === password
        );
        if (p2 === -1) {
          alert('密码错误');
          return;
        }
      }
      let lr = document.getElementById('lr');
      let un = document.getElementById('un');
      let myOrder = document.getElementById('myOrder');
      lr.style.display = 'none';
      un.innerHTML = account;
      myOrder.style.display = 'block';
      window.localStorage.setItem(
        '_u_',
        JSON.stringify({ u: account, d: new Date().getTime() })
      );
      document.getElementById('tijiao').value = '登录中...';
      setTimeout(() => {
        alert('登录成功');
      }, 1000);
      window.location = '#/';
    } else {
      alert('此用户名/手机号不存在，请先注册账号');
      return;
    }
  }

  componentWillMount() {}

  render() {
    const { value1, value2 } = this.state;
    return (
      <div style={{ width: '100%', height: '800px' }}>
        <Row
          style={{
            padding: '10px',
            width: '500px',
            margin: '0 auto',
            marginTop: '150px',
            lineHeight: '35px',
          }}
        >
          <Col span={24}>
            <div
              style={{
                border: '1px solid #e0e0e0',
                background: '#ebebeb',
                padding: '8px',
              }}
            >
              <div style={{ background: '#ffffff' }}>
                <div class="login_form_title">
                  <span>账号登录</span>
                </div>
                <div className="login_form_input">
                  <p className="erro_msg" id="message">
                    <span className="im"></span>
                    <span></span>
                  </p>
                  <ul>
                    <li>
                      <label>账号</label>
                      <input
                        type="text"
                        name="account"
                        id="account"
                        className="ant-input login_input"
                        placeholder="登录名/手机号"
                        autocomplete="off"
                      />
                    </li>
                    <li>
                      <label>密码</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="ant-input login_input"
                        placeholder="请输入密码"
                        autocomplete="off"
                      />
                    </li>
                    <li style={{ textAlign: 'center' }}>
                      <input
                        type="button"
                        style={{
                          height: '42px',
                          width: '150px',
                          marginTop: '30px',
                        }}
                        id="tijiao"
                        className="mywuda_sub_18"
                        value="登录"
                        onClick={() => {
                          this.login();
                        }}
                      ></input>
                    </li>
                    <li style={{ textAlign: 'right' }}>
                      还没有优米拉账号？赶紧
                      <a href="#/register">注册账号</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
