import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import IndexPage from './component/Home';
import ProductPage from './component/Product';
import ETMS from './component/ETMS/ETMS';
import ETMSHome from './component/ETMS/Home';
import AboutPage from './component/About';
import Nav from './component/Home/Nav';
import Privacy from './component/Privacy/Privacy';
import Agreement from './component/Agreement/Agreement';
import CustomerPage from './component/Solution/Customer';
import ForwarderPage from './component/Solution/Forwarder';
import Park from './component/Solution/Park';
import Join from './component/Join/Join';
import Help from './component/Help/Help';
import Forwarder from './component/Forwarder';
import fDetail from './component/Forwarder/fDetail';
import fDetailhw from './component/Forwarder/fDetailhw';
import myOrder from './component/Forwarder/myOrder';
import DespatchingCenter from './component/DespatchingCenter';
import order from './component/DespatchingCenter/order';
import Register from './component/Register/index';
import Login from './component/Login/Login.js';

import Footer1 from './component/Home/Footer1';

import {
  Nav00DataSource,
  Footer10DataSource,
} from './component/Home/data.source';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from 'react-router-dom';
import App from './App';
Object.setPrototypeOf = require('setprototypeof');
ReactDOM.render(
  <HashRouter>
    <App>
      <Nav dataSource={Nav00DataSource} />
      <Switch>
        <Route path="/product" exact component={ProductPage} />
        <Route path="/forwarder" exact component={Forwarder} />
        <Route path="/fDetail" exact component={fDetail} />
        <Route path="/fDetailhw" exact component={fDetailhw} />
        <Route path="/myOrder" exact component={myOrder} />
        <Route path="/despatchingCenter" exact component={DespatchingCenter} />
        <Route path="/order" exact component={order} />
        <Route path="/product/TMS" exact component={ETMS} />
        <Route path="/product/TMS/Home" exact component={ETMSHome} />
        <Route path="/about" exact component={AboutPage} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/agreement" exact component={Agreement} />
        <Route path="/solution/customer" exact component={CustomerPage} />
        <Route path="/solution/forwarder" exact component={ForwarderPage} />
        <Route path="/solution/park" exact component={Park} />
        <Route path="/join" exact component={Join} />
        <Route path="/support/contraband" exact component={Help} />
        <Route path="/" exact component={IndexPage} />
        <Route path="/register" exact component={Register} />
        <Route path="/login" exact component={Login} />
      </Switch>
      <Footer1 id="Footer1_0" key="Footer1_0" dataSource={Footer10DataSource} />
    </App>
  </HashRouter>,
  window.root
);
